import React from "react";
import GetWiZRHeader from "../components/atoms/Getwizrheader";
import AllVideos from "../components/ogranisms/AllVideos/AllArticles";
import ArticleBanner from "../components/ogranisms/VideoBanner/ArticleBanner";

function Videos() {
  return (
    <>
      <GetWiZRHeader />
      <ArticleBanner />
      <AllVideos />
    </>
  );
}
export default Videos;
