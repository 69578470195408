import * as styles from "./AllVideos.module.scss";
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { FiSearch } from "react-icons/fi";
import { videosData } from "../../../constants/videosData";
import VideoThumbnail from "../../molecules/VideoThumbnail/VideoThumbnail";
import Close from "../../../svg/close.svg";
import { videosTags } from "../../../constants/tagsData";
function AllVideos() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }

  const videoResults = videosData.filter(
    (video) =>
      video?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video?.tags?.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      video?.category?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  function handleClear() {
    setSearchTerm("");
  }
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <div
        className={`${styles.container} ${styles.videocontainer}   ${
          offset >= 1000 && `${styles.container1}`
        } ${offset >= 1800 && `${styles.container2}`} ${
          offset >= 4000 && `${styles.container3}`
        } ${offset >= 5000 && `${styles.container4}`}  `}
      >
        <div className={`${styles.toggle} togglearticle`}>
          <Link activeClassName="active" to="/explainer-videos">
            Go Back to Explorer Videos
          </Link>
        </div>

        <div className={styles.inputgroup}>
          <FiSearch />
          <input
            value={searchTerm}
            onChange={handleSearchChange}
            type={"text"}
            placeholder="Video Search..."
          />
          {searchTerm !== "" && (
            <button onClick={handleClear} className={styles.show}>
              Show All
            </button>
          )}
        </div>
        <div className={styles.tags}>
          <button
            className={styles.searchIcon}
            onClick={() => setSearchTerm("")}
          >
            <FiSearch /> Show All
          </button>
          {videosTags.map((item, i) => {
            return (
              <button
                key={i}
                className={
                  searchTerm?.includes(item.term) ? styles.activeTerm : ``
                }
                onClick={() => setSearchTerm(item.term)}
              >
                {item.tag}
              </button>
            );
          })}
        </div>
        <div className={styles.text}>
          {searchTerm === "social media" ? (
            <p>
              Primarily videos posted in Facebook and social media - getting
              over 2 million views
            </p>
          ) : searchTerm === "visual story" ? (
            <p>Videos expalining our visual story manuals</p>
          ) : searchTerm === "Show Room Series" ? (
            <p>Introduction videos part of our Show Room</p>
          ) : searchTerm === "library" ? (
            <p>Library is one of our key product modules</p>
          ) : searchTerm === "studio" ? (
            <p>Studio is one of our key product modules</p>
          ) : searchTerm === "product" ? (
            <p>All our products accessible in one overview</p>
          ) : (
            ""
          )}
        </div>

        <div className={styles.data}>
          {videoResults.map((video) => {
            return (
              <div onClick={() => setSelectedVideo(video)}>
                <VideoThumbnail
                  image={video?.thumbnail}
                  desc={video?.description}
                  tag={video?.category}
                  extraClass="allarticles"
                />
              </div>
            );
          })}
        </div>
        {videoResults?.length === 0 && (
          <div className={styles.nocontent}>
            <h3>
              No Content Found with <span>"{searchTerm}"</span>
            </h3>
          </div>
        )}
        <div className={styles.bottomD}>
          <p>
            {" "}
            <br /> Keep following what comes next <br />{" "}
          </p>
        </div>
        <div className={styles.bottom}></div>
        <div className={styles.ref}>
          <Link to="/demo">
            <p>
              Check out our product <span> Show Room</span>
            </p>
          </Link>
        </div>
      </div>
      {selectedVideo && (
        <div className={styles.popup}>
          <button onClick={() => setSelectedVideo(null)}>
            <Close />
          </button>
          <div className={styles.frame}>
            <video
              src={selectedVideo?.url}
              poster={selectedVideo?.poster}
              autoPlay={false}
              controls={true}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default AllVideos;
