import React from "react";
import * as styles from "./ArticleBanner.module.scss";

function ArticleBanner() {
  return (
    <div className={styles.main}>
      <div className={styles.slider}>
        <div className={styles.image}/>
      </div>
        <div className={styles.content}>
          <h1 className={styles.maintitle}>Video Gallery </h1>
          <p className={styles.info}>Explore our growing video collection </p>
        </div>
        </div>
    );
}
export default ArticleBanner;
