import img1 from "../images/promo1.jpg";
import img2 from "../images/promo2.jpg";
import img3 from "../images/promo3.jpg";
import img4 from "../images/promo4.jpg";
import img5 from "../images/promo5.jpg";
import img6 from "../images/promo6.jpg";
import img7 from "../images/promo7.jpg";
import img8 from "../images/promo8.jpg";
import img9 from "../images/promo9.jpg";
import img10 from "../images/promo10.jpg";
import img11 from "../images/promo11.jpg";
import img12 from "../images/promo12.jpg";
import img13 from "../images/promo13.jpg";
import img14 from "../images/promo14.jpg";
import img15 from "../images/promo15.jpg";
import img16 from "../images/promo16.jpg";

import poster1 from "../images/promoV1.png";
import poster2 from "../images/promoV2.png";
import poster3 from "../images/promoV3.png";
import poster4 from "../images/promoV4.png";
import poster5 from "../images/promoV5.png";
import poster6 from "../images/promoV6.png";
import poster7 from "../images/promoV7.png";
import poster8 from "../images/promoV8.png";
import poster9 from "../images/promoV9.png";
import poster10 from "../images/promoV10.png";
import poster11 from "../images/promoV11.png";
import poster12 from "../images/promoV12.png";

import poster13 from "../images/promoV13.png";
import poster14 from "../images/promoV14.png";
import poster15 from "../images/promoV15.png";
import poster16 from "../images/promoV16.png";


import image1 from "../images/libstudio2.jpg";
import image2 from "../images/conectmw2.jpg";

export const videosData = [
  {
    thumbnail: image1,
    title: "Intro Video",
    category: "Intro Video",
    poster: image1,
    description:"We touch base with our vision and essentials leading to why we would like to get in contact.",
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674065005/mwvideos/intromodules_isudka.mp4"
  },
  {
    thumbnail: image2,
    title: "Intro Video",
    category: "Intro Video",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674052402/mwvideos/wconnectindex2-2_egbj6i.mp4",
    poster:image2,
    description:"Explore other WiZR Connect videos and also make contact to explore it, but check this out first."
  },
  {
    thumbnail: img11,
    title: "We are hiring",
    category: "social media",
    poster: poster11,
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674057138/mwvideos/We_are_hiring_1_v246xc.mp4",
    description:"Hiring happens all the time, we keep having an open post for future communication stars"
  },
  {
    thumbnail: img3,
    title: "Future of work",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674057361/mwvideos/futureofwork_jdqxvt.mp4",
    poster: poster3,
    description:"We are at the breakpoint of hybrid, in person and digital work "
  },
  {
    thumbnail: img7,
    title: "Express Yourself",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1667838411/videos/Express_yourself_with_MASTER_WiZR_g6iquj.mp4",
    poster: poster7,
    description:"Find your vibe, style and signature. Having fun with sound and colors, groovy"
  },
  {
    thumbnail: img2,
    title: "Team Europe",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674063703/mwvideos/teameurope_1_bvjfyu.mp4",
    poster: poster2,
    description:
      "Asking some of our staff to comment on key concepts and vision",
  },
  {
    thumbnail: img4,
    title: "Imperfect Perfectionists",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674063801/mwvideos/imperfect_ocatci.mp4",
    poster: poster4,
    description:"Not all goes like planned when you are doing a video, here sharing our funny bloopers"
  },
  {
    thumbnail: img1,
    title: "Fun at work",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674063300/mwvideos/funatwork_pwdvtk.mp4",
    poster: poster1,
    description: "Posing in all other styles than the typical office portraits",
  },
  {
    thumbnail: img6,
    title: "Seasonal Greeting",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674063867/mwvideos/seasonalgreeting_pa71kv.mp4",
    poster: poster6,
    description:"We had a great 2022, a few highlights from the year, saying thank you to all 145K followers"
  },
  {
    thumbnail: img5,
    title: "Show Room",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674064074/mwvideos/showrooms_1_ifvha0.mp4",
    poster: poster5,
    description:"Show Rooms are like Play lists ways to organize content to be enjoyed anytime",
    tags:[
      'product'
    ]
  },
  {
    thumbnail: img10,
    title: "Shine Professionally",
    category: "social media",
    poster: poster10,
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674064255/mwvideos/shineprofessionally_zaxkzk.mp4",
    description:"We are in the business of making users of our software Shine Professionally"
  },
  {
    thumbnail: img12,
    title: "Our first promo",
    category: "social media",
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674065911/mwvideos/firstpromo_b4tvdi.mp4",
    poster: poster12,
    description:"2020, the year of vision and getting started. We have continued from our foundation"
  },
  {
    thumbnail: img8,
    title: "Streaming View",
    category: "social media",
    url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674064390/mwvideos/streamingview_atnng9.mp4",
    poster: poster8,
    description:"Streaming is not only for videos and music, we have done it for your presentations",
    tags:[
      'product'
    ]
  },
  {
    thumbnail: img9,
    title: "Virtual better than real",
    category: "social media",
    poster: poster9,
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674052063/mwvideos/virtualbetterthanreal_dg3vub.mp4",
    description:"With the vision to make virtual better than real we explore new ways to communicate"
  },
  {
    thumbnail: img13,
    title: "Our vision",
    category: "show room series",
    poster: poster13,
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960421/videos/Movie_1_Final_Video_Audio_Boost_fp5x8p.mp4",
    description:"Our vision is to impact the way you work and communicate, keep posted for more"
  },
  {
    thumbnail: img14,
    title: "Library/Studio",
    category: "show room series",
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960477/videos/Movie_2_Final_Audio_Update_bkznmf.mp4",
    poster: poster14,
    description:"Our starting product modules will be the foundation of all that comes next"
  },
  {
    thumbnail: img15,
    title: "WiZR Connect",
    category: "show room series",
    poster: poster15,
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670960914/videos/Movie_3_Final_Audio_Boost_wn8idr.mp4",
    description:"Next generation video conferencing in the making. Try it and see for yourself",
    tags:[
      'product'
    ]
  },
  {
    thumbnail: img16,
    title: "wizr x",
    category: "show room series",
    poster: poster16,
    url:"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1670961191/videos/Movie_4_Updated_Visuals_Final_ysuppv.mp4",
    description:"WiZR X is our product for communicating and connecting with people, chat, posts and more",
    tags:[
      'product'
    ]
  },
  // {
  //   thumbnail: img17,
  //   title: "Intro - Video",
  //   category: "show room series",
  //   poster: poster17,
  //   description:"Sharing essentials for what we will be prioritizing "
  // },
  // {
  //   thumbnail: img18,
  //   title: "Visual Story Library",
  //   category: "visual story",
  //   poster: poster18,
  //   description:"A visual tour through key sections and features of our special Library product module",
  //   tags:[
  //     'product'
  //   ]
  // },
  // {
  //   thumbnail: img19,
  //   title: "Visual Story Studio",
  //   category: "visual story",
  //   poster: poster19,
  //   description:"A visual tour through key sections and features of our colorful Studio product module",
  //   tags:[
  //     'product'
  //   ]
  // },
  // {
  //   thumbnail: img20,
  //   title: "Visual Story WiZR Connect",
  //   category: "visual story",
  //   poster: poster20,
  //   description:"A visual tour through key sections and features of our next gen video conferencing system ",
  //   tags:[
  //     'product'
  //   ]
  // },
  // {
  //   thumbnail: img21,
  //   title: "Visual Story WiZR X",
  //   category: "visual story",
  //   poster: poster21,
  //   description:"A visual tour through key sections and features of new ways to chat, post and build profiles",
  //   tags:[
  //     'product'
  //   ]
  // },
];
