import React from "react";
import * as styles from "./VideoThumbnail.module.scss";
import {FaPlay} from "react-icons/fa";

function VideoThumbnail({image,tag,title,desc,link,extraClass}){
    const MAX_LENGTH = 200;
    return(
        <div className={`${styles.whitecard} ${styles[extraClass]}`}>
            <div style={{backgroundImage:`url(${image})`}} className={styles.image}><button><FaPlay /></button></div>
           <div className={styles.content}>
           <h3
           style={{color:`${tag==="Productivity" ? `#4A74BE` : tag==="Lifestyle" ? `#3787C1`: tag==="Creativity" ? `#ec7600` : `#078367`}`}}
           >{tag}</h3>
            <h2>{title}</h2>
            <p dangerouslySetInnerHTML={desc?.length > MAX_LENGTH ? {__html: `${desc?.substring(0,MAX_LENGTH)}...`} : {__html:desc}}></p>
           </div>
        </div>
    );
}

export default VideoThumbnail;